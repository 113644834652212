import React, { useContext } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Button from '../../components/287634/button/button';
import { returnWhatsapp } from '../../utils/browser';
import Layout from '../../components/287634/layout/layout';
import { CompanyContext } from '../../context/company.context';
import { LangContext } from '../../context/lang.context';

const ErrorPage = () => {
  const company = useContext(CompanyContext);
  const { t } = useContext(LangContext);
  const data = useStaticQuery(graphql`
    query {
      allI18NJson {
        nodes {
          locale
          clientId
          RETURN_WHATSAPP
          ERROR {
            TITLE_1
            TITLE_2
          }
        }
      }
    }
  `);

  return (
    <Layout>
      <section className="flex flex-auto flex-col items-center justify-center">
        <img src={company.getPublicURL(`/theme/assets/images/error/generic-error.svg`)} alt="Error" />
        <h3 className="mb-11 text-center text-base text-red">
           !Oh, no! Esta página ya no está disponible.
        </h3>
      </section>
      <Button type="button" onClick={() => returnWhatsapp()} className="mx-auto">
        Volver a WhatsApp
      </Button>
    </Layout>
  );
};

export default ErrorPage;
